import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { Box } from 'grommet';
import PropTypes from 'prop-types';

import * as UserDucks from 'granite-admin/accounts/ducks/user';
import * as OrganisationDucks from 'granite-admin/organisations/ducks/organisations';
import useQuery from 'granite-admin/utils/useQuery';
import { getGrandEmitter } from 'granite-admin/utils/grandEmitter';
import {
  getAuthToken,
  getOrganisation,
  setAuthToken,
  getMyOrganisations,
  setExtraAPIParams,
} from 'granite-admin/utils/auth-singleton';
import organisationAPIGateway from 'granite-admin/organisations/gateways/organisation-api';
import DashboardLayout from 'granite-admin/core/components/DashboardLayout/index';
import Loader from 'granite-admin/core/components/Loader';
import { getMyProfile } from 'common/controller/common';
import { deactivatePlugin } from 'konect/controllers';

function fetchUserProfile(dispatch, profile_pk) {
  getMyProfile()
    .then(response => {
      const newUser = { ...response, plugin_profile_pk: profile_pk ? +profile_pk : 90 };
      dispatch({
        type: 'accounts/user/UPDATE_PROFILE',
        data: newUser,
      });
    })
    .catch(err => console.error(err));

  return (
    <Box align="center" height="100%" justify="center">
      <Loader />
    </Box>
  );
}

function fetchOrganisations(dispatch) {
  organisationAPIGateway
    .myOrganisations()
    .then(response => {
      dispatch({
        type: 'organisations/UPDATE_ORGANISATIONS',
        data: response,
      });
      if (response[0]) {
        setSelectedOrganisation(response[0], dispatch);
      }
    })
    .catch(err => console.error(err));
  return (
    <Box align="center" height="100%" justify="center">
      <Loader />
    </Box>
  );
}

function setSelectedOrganisation(org, dispatch) {
  dispatch({
    type: 'organisations/UPDATE_SELECTED_ORGANISATION',
    data: org,
  });
}

const PrivateRoute = ({
  component,
  layout,
  roles,
  userProfile,
  selectedOrganisation,
  dispatch,
  breadcrumbPath,
  organisations,
  path,
  heading,
  actions,
  tabs,
  addTabBtn,
  permissions,
  noHeader,
  ...rest
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const grandEmitter = useMemo(() => getGrandEmitter(), []);
  useEffect(() => {
    const subscription = grandEmitter.getObservable().subscribe(event => {
      switch (event.type) {
        case 'DEACTIVATE_PLUGIN':
          deactivatePlugin(grandEmitter, userProfile.plugin_profile_pk);
          break;
        case 'DEACTIVATE_PLUGIN_SUCCESS':
          navigate('/error');
          break;
        case 'DEACTIVATE_PLUGIN_FAILURE':
          alert('Failed to deactivate', 'status-error');
          break;
        default:
          console.log('not handled', event);
          break;
      }
    });

    return () => subscription.unsubscribe();
  }, [grandEmitter, navigate, userProfile]);

  let authToken = getAuthToken();
  const localOrganisation = getOrganisation();
  const localMyOrganisations = getMyOrganisations();

  const { query } = useQuery();

  if (query.access_token && query.access_token !== authToken) {
    setAuthToken(query.access_token);
    authToken = query.access_token;
  }

  if (authToken && !userProfile.username) {
    return fetchUserProfile(dispatch, query.profile_pk);
  }

  if (!authToken) {
    return <Navigate to="/login" />;
  }

  if (authToken && !userProfile.isSystemAdmin && !organisations) {
    return fetchOrganisations(dispatch);
  }

  if (!selectedOrganisation?.pk && localOrganisation && localOrganisation !== 'undefined') {
    setSelectedOrganisation({ pk: localOrganisation }, dispatch);
  }

  if (!organisations && localMyOrganisations && localMyOrganisations !== 'undefined') {
    dispatch({
      type: 'organisations/UPDATE_ORGANISATIONS',
      data: localMyOrganisations,
    });
  }

  if (query.date_format) setExtraAPIParams({ datetime_format: query.date_format });
  if (query.timezone) setExtraAPIParams({ user_timezone: query.timezone });
  return (
    <DashboardLayout
      pathname={location.pathname}
      // eslint-disable-next-line react/prop-types
      navigate={navigate}
      breadcrumbPath={breadcrumbPath}
      heading={heading}
      actions={actions}
      addTabBtn={addTabBtn}
      permissions={permissions}
      noHeader={noHeader}
      tabs={tabs}
      childProps={{
        navigate: navigate,
        location: location,
      }}
      component={component}
      footerLabel="Powered by T2B Konect"
      backgroundColor="#FFFFFF"
      noSidebar
      noAlert
      noFooter
      {...rest}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.any,
  layout: PropTypes.any,
  roles: PropTypes.any,
  userProfile: PropTypes.any,
  selectedOrganisation: PropTypes.any,
  dispatch: PropTypes.any,
  breadcrumbPath: PropTypes.any,
  organisations: PropTypes.any,
  path: PropTypes.any,
  heading: PropTypes.any,
  actions: PropTypes.any,
  tabs: PropTypes.any,
  addTabBtn: PropTypes.any,
  permissions: PropTypes.any,
  noHeader: PropTypes.bool,
};

const mapStateToProps = state => ({
  userProfile: UserDucks.profile(state),
  selectedOrganisation: OrganisationDucks.selectedOrganisation(state),
  organisations: OrganisationDucks.organisations(state),
});

const mapDispatchToProps = dispatch => ({
  dispatch: dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
