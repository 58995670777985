class ProfileProcess {
  constructor({ ...data }) {
    this.pk = data?.pk;
    this.name = data?.name;
    this.subscriber_profile = data?.subscriber_profile;
    this.organisation = JSON.stringify(data?.extra_data?.organisations) || 'NA';
    this.process_master = data?.process_master;
    this.last_executed_at = data?.last_executed_at;
    this.last_executed_result = data?.last_executed_result;
    this.endpoint_type = data?.endpoint_type;
    this.parent_pk = data?.parent_pk;
    this.web_api = data?.web_api;
    this.cache_expiry = data?.cache_expiry;
    this.request_type = data?.request_type;
    this.request_object = data?.request_object;
    this.response_object = data?.response_object;
    this.process_type = data?.process_type;
    this.schedule = data?.schedule;
    this.notification_object = data?.notification_object;
    this.authentication_type = data?.authentication_type;
    this.authentication_object = data?.authentication_object;
    this.synchronous_response = data?.synchronous_response;
    this.default_destination = data?.default_destination;
    this.retry_count = data?.retry_count;
    this.retry_time_gap = data?.retry_time_gap;
    this.is_active = data?.is_active;
    this.is_deleted = data?.is_deleted;
    this.created_at = data?.created_at;
    this.modified_at = data?.modified_at;
    this.actions = data?.actions;
  }
}

export default ProfileProcess;
