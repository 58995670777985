import { ORGANISATION_EVENTS } from 'granite-admin/organisations/controllers/constants';
import pluginAPI from 'granite-admin/plugins/gateways/plugin-api';
import konectApi from 'konect/gateways/konect-api';

async function validateToken(eventEmitter, plugin_profile_pk) {
  try {
    const response = await konectApi.getMyDetails(plugin_profile_pk);
    eventEmitter.emit('TOKEN_VALIDATION_SUCCESS', response);
  } catch (error) {
    eventEmitter.emit('TOKEN_VALIDATION_FAILURE');
  }
}

async function updateSettings(eventEmitter, values, plugin_profile_pk) {
  try {
    await Promise.all(
      values.map(
        async val =>
          await konectApi.updateSettings(val?.pk, {
            value: val.value,
            subscriber_profile: { pk: plugin_profile_pk },
            settings_master: { pk: val.masterpk },
          }),
      ),
    );

    eventEmitter.emit(ORGANISATION_EVENTS.SETTINGS_SAVE_SUCCESS);
  } catch (error) {
    eventEmitter.emit(ORGANISATION_EVENTS.SETTINGS_SAVE_FAILURE);
  }
}

async function deactivatePlugin(eventEmitter, plugin_profile_pk) {
  try {
    const {
      source_auth_object: { access_token },
      source_profile_id,
    } = await konectApi.getMyDetails(plugin_profile_pk);

    const data = await pluginAPI.deactivatePlugin(source_profile_id, access_token);
    eventEmitter.emit('DEACTIVATE_PLUGIN_SUCCESS', data);
  } catch (err) {
    eventEmitter.emit('DEACTIVATE_PLUGIN_FAILURE');
  }
}

export { validateToken, updateSettings, deactivatePlugin };
